<template>
  <Content>
      <Fanpage/>
  </Content>
</template>
<script>
import Content from "@/components/LeadAd/Content.vue";
import Fanpage from "@/components/LeadAd/Fanpage.vue";

export default {
  components: {
    Content,
    Fanpage
  },
};
</script>