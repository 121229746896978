<template>
  <div>
    <Modal :idModal="idModal" @modalShow="modalShow" @modalHide="modalHide">
      <div class="container">
        <div class="row p-4" v-if="permissionOfGetFormFb">
          <div class="col-12">
            <img
              class="float-right"
              :src="require('@/assets/icons/close.svg')"
              alt="Voltar"
              @click="$bvModal.hide(idModal)"
              id="icon-close"
            />
          </div>

          <div class="col-12" id="content-title">
            <p class="m-0" id="title">Cadastrar integração</p>
            <small class="sub-title"
              >Adicionar integração atribuindo os formulários</small
            >
          </div>

          <div class="col-12">
            <p class="title mt-0">Fanpage</p>
            <input
              type="text"
              class="form-control w-100 input-name-fanpage"
              disabled
              :placeholder="fanpageName"
            />
          </div>

          <div class="col-12">
            <label class="typo__label title">Formulário do Facebook</label>
            <multiselect
              v-model="formFbSelected"
              :options="formFb"
              deselectLabel=""
              selectLabel=""
              tag-placeholder=""
              selectedLabel="Selecionado"
              :placeholder="nameFormFb"
              label="name"
              track-by="name"
              :disabled="disabledSelectFormFb"
              :showNoResults="false"
            ></multiselect>            
          </div>
          <div class="col-12">
            <label class="typo__label title">Formulário da G Digital</label>
            <multiselect
              v-model="formGSelected"
              id="ajax"
              label="name"
              track-by="code"
              deselectLabel=""
              selectLabel=""
              tag-placeholder=""
              selectedLabel="Selecionado"
              :placeholder="nameFormGDigital"
              open-direction="bottom"
              :options="formGDigital"
              :multiple="false"
              :searchable="true"
              :loading="loadingSearch"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              @search-change="getFormGDigital"
            >
            </multiselect>
          </div>
          <div class="col-12">
            <button
              class="btn btn-action-integration"
              @click="integration"
              :disabled="btnDisabled"
            >
              {{ nameBtn }}
            </button>
          </div>
        </div>
        <UpdateConfigurationFanpage
          v-else
          @loged="modalShow"
          :fanpageName="fanpageName"
          :idModal="idModal"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../BaseModal.vue";
import Multiselect from "vue-multiselect";
import UpdateConfigurationFanpage from "./UpdateConfigurationFanpage.vue";

import LeadAdService from "@/services/resources/LeadAdService.js";
import FormService from "@/services/resources/FormService.js";
import LeadAdFormService from "@/services/resources/LeadAdFormService.js";
import leadgenForIntegration from "@/services/resources/leadgenForIntegration.js"

import { mapMutations } from "vuex";

const leadgenForIntegrationService = leadgenForIntegration.build()
const LeadAdFormApiService = LeadAdFormService.build();
const FormApiService = FormService.build();
const LeadAdApiService = LeadAdService.build();

export default {
  name: "ModalIntegration",
  props: {
    idModal: {
      required: true,
    },
    fanpageName: {
      required: true,
    },
    edit: {
      type: Object,
    },
  },
  components: {
    Modal,
    Multiselect,
    UpdateConfigurationFanpage,
  },
  data: () => ({
    nameFormFb: "Formulário do Facebook",
    nameFormGDigital: "Formulário G Digital",
    optionsLeagensEncontrado: [{code: true, name: 'Adicionar na integração'}, {code: true, name: 'Não adicionar na integração'}],
    leagensEncontradoSelected: null,
    formFb: [],
    formGDigital: [],
    formGSelected: null,
    formFbSelected: null,
    loadingSearch: false,
    disableBtn: false,
    permissionOfGetFormFb: true,
    leadgensEncontrado: false
  }),
  computed: {
    getId: function () {
      return this.$route.params.id;
    },
    btnDisabled() {
      if (this.disableBtn) {
        return true;
      } else if (this.formFbSelected && this.formGSelected) {
        return false;
      } else if (this.formGSelected && this.edit.id) {
        return false;
      }
      return true;
    },
    nameBtn() {
      return this.edit.id ? "Atualizar integração" : "Adicionar integração";
    },
    disabledSelectFormFb() {
      if (this.edit.id) {
        return true;
      }
      return this.formFb.length === 0;
    },
    getMessageUpdateOrCreate() {
      if (this.edit.id) {
        return "Formulário atualizado com sucesso!";
      }
      return "Formulário adicionado com sucesso!";
    },
  },
  methods: {
    ...mapMutations(["setMessage", "loading", "alertError"]),
    async modalShow() {
      this.permissionOfGetFormFb = true;
      this.formGSelected = null;
      this.formFbSelected = null;
      this.loading(true);
      await this.getFormGDigital();
      if (!this.edit.id) {
        await this.getFormFacebook();
      } else {
        this.formGSelected = {
          code: this.edit.form_g_digital_id,
          name: this.edit.form_g_digital,
        };
        this.formFbSelected = {
          code: this.edit.fb_form_id,
          name: this.edit.fb_form_title,
        };
      }
      this.loading(false);
    },
    modalHide() {
      this.$emit("modalHide");
    },
    async getFormGDigital(query) {
      try {
        this.loadingSearch = true;
        this.formGDigital = [];
        if (query === undefined) {
          query = "";
        }
        const formsG = await FormApiService.read("/search?search=" + query);
        for (const formG of formsG) {
          this.formGDigital.push({ code: formG.ID, name: formG.post_title });
        }
      } catch (e) {
        this.setMessage({
          message: "Falha ao buscar formulários da G Digital",
          bg: "danger",
        });
      }
      this.loadingSearch = false;
    },
    async getFormFacebook() {
      try {
        this.loading(true);

        this.formFb = [];
        let forms = await LeadAdApiService.read(
          "/fanpage/" + this.getId + "/facebook/form"
        );

        if (forms.code && forms.code === 200) {
          this.permissionOfGetFormFb = false;
        }
        if (forms.error) {
          this.setMessage({ message: forms.error, bg: "danger" });
        } else {
          if (!forms.data[0]) {
            this.setMessage({
              message:
                "Não foi localizado formulários de sua fanpage para integração.",
              bg: "danger",
            });
          }
          for (const form of forms.data) {
            this.formFb.push({ code: form.id, name: form.name });
          }
        }
      } catch (e) {
        this.setMessage({
          message: "Falha ao buscar formulários do facebook",
          bg: "danger",
        });
      }
      this.loading(false);
    },
    async integration() {
      try {
        this.loading(true);
        this.disableBtn = false;
        const data = {
          fanpage_id: this.getId,
          fb_form_id: this.formFbSelected.code,
          fb_form_title: this.formFbSelected.name,
          form_id: +this.formGSelected.code,
        };
        let formIntegration = null;

        if (this.edit.id) {
          formIntegration = await LeadAdFormApiService.update(
            Object.assign(data, { id: this.edit.id })
          );
        } else {
          formIntegration = await LeadAdFormApiService.create(data);
        }
        if(formIntegration.hasLeadads){
              this.hasLeadads(formIntegration)
        }

        if (!formIntegration.error) {
          this.setMessage({
            message: this.getMessageUpdateOrCreate,
            bg: "success",
          });
          this.$bvModal.hide(this.idModal);
          this.$emit("integration", this.edit.id ?? false);
          this.loading(false);
          this.disableBtn = false;
          return;
        }
        this.alertError(formIntegration);
        this.loading(false);
      } catch (e) {
        console.log(e);
        this.loading(false);
        this.setMessage({
          message: "Desculpe falha ao salvar Formulário",
          bg: "danger",
        });
      }
      this.disableBtn = false;
    },
    async hasLeadads(data){
      
      let res = await this.$swal({
        title: '',
        icon: 'info',
        html:'Desejá adicionar lead capturadas pelo sistema antes da integração?',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:'Adicionar',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText:'Cancelar',
        cancelButtonAriaLabel: 'Thumbs down',
          confirmButtonColor: '#00e4a0',
      });

      if(res.isConfirmed){
        
        let leadgens = await leadgenForIntegrationService.update({id: data.id});
        if(leadgens.error){
          this.setMessage({
            message: "Desculpe falha ao adicionar leads já cadastradas",
            bg: "danger",
          });
        }
        this.setMessage({
            message: "Leads atualizado com sucesso.",
            bg: "success",
        });
        this.$emit("integration", false);
      }
    }
  },
};
</script>


<style >
.multiselect__placeholder {
  color: #000;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: none;
}

.multiselect--disabled > .multiselect__tags,
.multiselect--disabled > .multiselect__tags > .multiselect__single {
  background-color: #d2d9e0 !important;
  border: 1px solid #ced4da;
}

#icon-close {
  cursor: pointer;
}

.input-name-fanpage {
  width: 100%;
  height: 42px;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #000;
}

.icon-fanpage {
  width: 45px;
  height: 45px;
}

.btn-action-integration {
  width: 100%;
  height: 50px;

  margin-top: 35px;

  background: #000916;
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin-bottom: 15px;
}

#title,
.title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 20px;
  color: #000000;
}

.sub-title {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #8c8a97;
}

input {
  height: 50px;
}

.btn-action-integration:hover {
  color: #ffffff;
}

/*
  
*/
</style>