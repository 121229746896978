<template>
  <div class="content">

    <HeaderFanpage
      :fanpage="fanpage"
      :IdModalIntegration="IdModalIntegration"
      :IdModalDisassociateFanPage="IdModalDisassociateFanPage"
     />
     <ModalIntegration 
          :idModal="IdModalIntegration"
          :fanpageName="fanpage.fb_fanpage_title" 
          :edit="edit"
          @integration="integration"
          @modalHide="modalIntegrationHide"
        />
    <ModalDisassociateFanPage :idModal="IdModalDisassociateFanPage"/>
    <div>
      <b-table
        :borderless="true"
        :items="items"
        :fields="fields"
        :fixed="false"
        show-empty
      >
        <template #empty="scope">
          <TableEmpty 
          text="Nenhuma Integração foi encontrata "
          />
      </template>

        <template #cell(id)="row">
          # {{row.item.id}}
        </template>

        <template #cell(integrations)="row">
            <div class="d-flex align-items-center">
              <span class="d-inline-block text-truncate" style="max-width: 150px;" :title="row.item.integration" >{{row.item.integration}}</span>
              <img :src="require('@/assets/icons/facebookBlack.svg')" alt="Facebook">
              <img :src="require('@/assets/icons/lightningGreen.svg')" alt="Integração">
              <img :src="require('@/assets/icons/gDigital.svg')" alt="Integração">
              <span class="d-inline-block text-truncate" style="max-width: 150px;" :title="row.item.form_g_digital">{{row.item.form_g_digital}}</span>
            </div>
        </template>
        <template #cell(actions)="row">
          <b-link size="sm" class="mr-1 link-action-form btn-edit" 
            @click="editIntegration(row.item)"
            >
              EDITAR
          </b-link>
          <b-link size="sm" class="mr-1 link-action-form btn-history" 
          :to="`/lead-ads/${idFanpage}/leadgen/`+row.item.fanpage_form_integration">
              HISTÓRICO
          </b-link>
          <b-link size="sm" class="mr-1 link-action-form btn-remove" @click="removeIntegration(row.item)">
              REMOVER
          </b-link>
        </template>
      </b-table>
      <VFacebookLoginScope :app-id="getIdApp" />
      <Footer  
        v-model="currentPage"
        :rows="getTotalRows"
        :perPage="getPerPage"
        @change="page"
      />
    </div>
  </div>
</template>

<script>
import LeadAdService from "@/services/resources/LeadAdService.js";
import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component";
import FormLeadAdForm from "@/services/resources/LeadAdFormService.js";
import Footer from './Footer.vue';
import moment from "moment";
import HeaderFanpage from "./HeaderFanpage.vue";
import ModalDisassociateFanPage from './ModalDisassociateFanPage.vue';
import ModalIntegration from './Modalntegration.vue'
import TableEmpty from './TableEmpty.vue'

const LeadAdApiService = LeadAdService.build();
const LeadAdFormApiService = FormLeadAdForm.build();

import {mapMutations} from 'vuex'

export default {
  name: "Fanpage",
  components: {
    HeaderFanpage,
    Footer,
    ModalDisassociateFanPage,
    ModalIntegration,
    TableEmpty,
    VFacebookLoginScope,

  },
  data: () => ({
    IdModalDisassociateFanPage:'modal-dissaccociate-FanPage',
    IdModalIntegration: 'modal-integration',
    fanpage: {},
    idFanpage: null,
    fields: [
      { key: 'id', label: '# ID' },
      "Criado em",
      "Atualizado em",
      { key: 'integrations', label: 'Integração' },
      "Último Lead Cap.",
      { key: 'actions', label: 'Ações' },
    ],
    items: [],
    currentPage:1,
    getTotalRows:0,
    getPerPage:0,
    numberPage: 0,
    edit: {}
  }),
  computed:{
    getIdApp: () => process.env.FACEBOOK_APP_ID,
  },
  created: async function () {
    this.loadDataHeader()
  },
  methods: {
    ...mapMutations(['loading', 'setMessage']),
    async loadDataHeader(){
      this.loading(true)
      const id = this.$route.params.id;
      this.idFanpage = id;
      try{
        const fanpage = await LeadAdApiService.read(id);
        this.fanpage = fanpage;
        await this.setDataItemTable();
      }catch(e){
        console.log(e)
        this.setMessage({message: 'Falha ao carregar dados da Fanpage.', bg:'danger'})
      }
      this.loading(false)
    },
    openModalIntegration(){
      this.$bvModal.show(this.IdModalIntegration)
    },
    modalIntegrationHide(){
      this.edit = {}
    },
    editIntegration(data){
      this.edit = data
      this.$bvModal.show(this.IdModalIntegration)
    },
    setDataForPaginate(formsFanpage){
      this.currentPage = formsFanpage.current_page
      this.getTotalRows = formsFanpage.total
      this.getPerPage = formsFanpage.per_page
    },
    async getDateForm(page){
      if(!page && !this.numberPage){
        this.numberPage = 1
      }else if(page !== undefined){
        this.numberPage = page;
      }
      
      let formsFanpage = await LeadAdApiService.read(
        "fanpage/" + this.idFanpage + "/forms?page="+this.numberPage
      );

      if(formsFanpage.error){

        this.setMessage({message: 'Falha ao carregar dados de formulários.', bg:'danger'})
      }
      return formsFanpage
    },
    async integration(edit){
      await this.setDataItemTable()
      if(!edit){
        this.fanpage.integrations ++;
      }
    },
    async setDataItemTable(){
      const formsFanpage = await this.getDateForm()
      this.setDataForPaginate(formsFanpage);
      this.formatData(formsFanpage)
    },
    formatData(formsFanpage){
      this.items = []
      for (let formFanpage of formsFanpage.data) {
        this.items.push({
          id:formFanpage.id,
          "Criado em": moment(formFanpage.created_at).format('DD/MM/YYYY HH:MM'),
          "Atualizado em":moment(formFanpage.updated_at).format('DD/MM/YYYY HH:MM'),
          "integration": formFanpage.fb_form_title,
          "Último Lead Cap.": formFanpage.last_catch??'Aguardando Leads',
          form_g_digital: formFanpage.form_g_digital,
          form_g_digital_id: formFanpage.form_g_digital_id,
          fb_form_id: formFanpage.fb_form_id,
          fb_form_title: formFanpage.fb_form_title,
          fanpage_form_integration: formFanpage.fanpage_form_integration.id
        });
      }
    },
    async removeIntegration(item){
      let res = await this.$swal({
        title: '<strong>Remover integração</strong>',
        icon: 'info',
        html:'Desejá realmente remover a integração?',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:'Remover',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText:'Cancelar',
        cancelButtonAriaLabel: 'Thumbs down',
        confirmButtonColor: '#FF0C37',
      });
      
      if(res.isConfirmed){
        this.loading(true)  
        let deleted = await LeadAdFormApiService.destroy({id:item.id})
        this.loadDataHeader()
        if(!deleted.error){
          this.setMessage({message: 'Integração removida com sucesso.', bg:'success'})
          this.setDataItemTable();
          this.loading(false)
          return;
        }
        this.setMessage({message: 'Falha ao remover a integração.', bg:'danger'})
        this.loading(false)
      }

    },
    async page(page){
      this.loading(true)
      const formsFanpage = await this.getDateForm(page);
      this.items = [];
      this.formatData(formsFanpage)
      this.loading(false)
    }
  },
};
</script>

<style scoped>
    img{
      padding:5px
    }
</style>

<style >

.link-action-form:hover{
  text-decoration: none !important;
}

.link-action-form{
  margin-right: 10px !important;
}

.btn-edit, .btn-history, .btn-remove{
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}

table>tbody{
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #81858E;
}

.btn-edit{
  color: #2133D2;
} 

.btn-edit:hover{
  color: #2133D2;
} 
.btn-history:hover{
  color: #00E4A0;
}
.btn-history{
  color: #00E4A0;
}
.btn-remove:hover{
  color: #FF0C37;
}
.btn-remove{
  color: #FF0C37;
}

table {
  margin-top: 41px;
}

thead > tr > th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #333333;
}

</style>