<template>
    <LoginFacebook
      :disableBtn="disableBtn"
      v-if="showButton"
      @loged="updateTokenFanpage"
      :isLoged="false"
      custonClass="pulse pulse-green "
      :title="nameButton"
    />
</template>

<script>
import LeadAdService from "@/services/resources/LeadAdService.js";
const LeadAdApiService = LeadAdService.build();
import { mapMutations } from "vuex";

import LoginFacebook from "./LoginFacebook.vue";

export default {
  name: "UpdateTokenFanpage",
  props: {
    fanpage: {
      required: true,
    },
    type: {
      required: true,
    },
  },
  data: () => ({
    disableBtn: false,
  }),
  components: {
    LoginFacebook,
  },
  computed: {
    showButton() {
      switch (this.fanpage.status) {
        case "token_error":
          return true;
        case "subscription_error":
          return true;
        default:
          return false;
      }
    },
    nameButton() {
      switch (this.type) {
        case "token":
          return "Atualizar token";
        default:
          return "Refazer inscrição";
      }
    },
    message() {
      switch (this.type) {
        case "token":
          return "Token atualizado com sucesso.";
        default:
          return "Inscrição atualizada com sucesso.";
      }
    },
    messageError() {
      switch (this.type) {
        case "token":
          return "Falha ao atualizar token.";
        default:
          return "Falha atualizada Inscrição da Fanpage.";
      }
    },
  },
  methods: {
    ...mapMutations(["loading", "setMessage"]),
    findFanpage(fb_fanpage_id, fanpages){
      return fanpages.data.find((item)=>{
        if(+item.id === +fb_fanpage_id){
          return item;
        }
      })
    },
    async updateTokenFanpage(fanpages) {
      const response = this.findFanpage(this.fanpage.fb_fanpage_id, fanpages)
      if(!response){
        this.setMessage({
            message: `Não foi localizado a Fanpage ${this.fanpage.fb_fanpage_title}. Por favor verifique as permissões da pagina`,
            bg: "danger",
        });
        return;
      }
      this.disableBtn = true;
      this.loading(true);
      try {
          if (response.access_token) {
            let data = {
              id: this.fanpage.id,
              fb_fanpage_token: response.access_token,
              status: "waiting_leads",
            };

            if (this.type === "token") {
              await this.update(data);
            } else {
              await this.update(
                Object.assign(data, { "re-enrollment": true })
              );
            }
          } else {
            this.setMessage({
              message: "Falha ao buscar token no Facebook.",
              bg: "danger",
            });
          }
          this.disableBtn = false;
      } catch (e) {
        console.log(e);
        this.setMessage({
          message: "Falha ao buscar token no Facebook.",
          bg: "danger",
        });
      }
      this.loading(false);

    },
    async update(response) {
      try {
        let responseApi = await LeadAdApiService.update(response);
        if (responseApi.error) {
          this.setMessage({ message: responseApi.error, bg: "danger" });
        } else {
          this.fanpage.status = responseApi.status;
          this.setMessage({ message: this.message, bg: "success" });
        }
      } catch (e) {
        console.log(e);
        this.setMessage({ message: this.messageError, bg: "danger" });
      }
      this.loading(false);
    },
  },
};
</script>

<style>

.pulse {
  margin-right: 25px;
  height: 50px !important;
  width: 216px !important;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  border: 0 !important;
  color: #fff;
  transition: 0.3s ease;
  outline: none;
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.pulse-blue {
  box-shadow: 0 0 0 0 #3b37ff;
}

.pulse-green {
  box-shadow: 0 0 0 0 #3dcb96;
  background-color: #3dcb96 !important;
}

.pulse:active {
  border: 0 !important;
}

.pulse:hover {
  color: #fff !important;
}
@keyframes pulse {
  100% {
    box-shadow: 0 0 0 10px rgb(61 203 150 / 0%);
  }
}
</style>