<template>
  <div>
    <Header>
      <div class="d-flex">
        <ErrorIntegration :fanpage="fanpage" :type="typeErrorIntegration"/>
        <button 
            v-b-modal.modal-new-integration-fanPage
            @click="$bvModal.show(IdModalIntegration)"
            v-if="showButtonNewIntegration"
            class="btn btn-integration">Nova Integração</button>
        <button
          v-b-modal.modal-disassociate-fanPage
          class="btn-open-modal-dissociation-fanpage"
          @click="$bvModal.show(IdModalDisassociateFanPage)"
        >
          Desassociar Fanpage
        </button>
      </div>
        <template v-slot:link>
          <router-link to="/lead-ads" class="link-page d-inline">
              Listagem de Fanpages
          </router-link>
          <div class="d-inline text-item">
            /
            {{fanpage.fb_fanpage_title}}
          </div>
        </template>
    </Header>
    <div class="row col-12 w-100 content-info">
      <div class="col-3 d-flex pl-0">
        <div class="content-icon mr-3">
          <img :src="imgCalendar" alt="Calendario" />
        </div>
        <div>
          <span class="title">Data da Associação</span>
          <br />
          <span class="text-secondary">{{ getCreatedAt }}</span>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-center">
        <div class="content-icon mr-3">
          <img :src="imgLeadCap" alt="Lead capturado" />
        </div>
        <div>
          <span class="title">Último Lead capturado</span>
          <br />
          <span class="text-secondary">{{ getLastCatch}}</span>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-center">
        <div class="content-icon mr-3">
          <img :src="imgCalendar" alt="Calendario" />
        </div>
        <div>
          <span class="title">Data de Atualização</span>
          <br />
          <span class="text-secondary">{{ getUpdated }}</span>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-end">
        <div class="content-icon mr-3">
          <img :src="imgPeople" alt="Calendario" />
        </div>
        <div>
          <span class="title">Leads capturados</span>
          <br />
          <span class="text-secondary">{{ this.fanpage.total_leads }}</span>
        </div>
      </div>
    </div>
    <div class="total-integration row col-12">
      <span class="col-12 d-flex justify-content-end">{{fanpage.integrations}} Integração encontradas</span>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import moment from "moment";
import ErrorIntegration from './ErrorIntegration.vue'

export default {
  name: "HeaderFanpage",
  props: {
    fanpage: {
      type:Object,
      required: true,
    },
    IdModalIntegration: {
      required: true,
    },
    IdModalDisassociateFanPage: {
      required: true,
    },
  },
  components: {
    Header,
    ErrorIntegration,
  },
  
  data: () => ({
    imgCalendar: require("@/assets/icons/calendar.svg"),
    imgLeadCap: require("@/assets/icons/lightning.svg"),
    imgPeople: require("@/assets/icons/people.svg"),
    timeAssociation: "",
  }),
  computed: {
    getCreatedAt: function () {
      return moment(this.fanpage.created_at).format("DD/MM/YYYY HH:MM");
    },
    getUpdated:function (){
      return moment(this.fanpage.updated_at).format("DD/MM/YYYY HH:MM");
    },
    getLastCatch: function() {
      return this.fanpage.last_catch ?? 'Aguardando Leads'
    },
    fbFanpageId: function() {
      return this.fanpage ?? {}
    },
    showButtonNewIntegration: function() {
      
      if(this.fanpage.status !== 'token_error' && this.fanpage.status !== 'subscription_error'){
        return true;  
      }
      return false
    },
    typeErrorIntegration: function(){
      switch(this.fanpage.status){
        case 'token_error':
          return 'token'
        case 'subscription_error':
          return 'subscription'
      }
      return false
    },

  },
};
</script>

<style scoped>

.text-secondary{
  font-size: 14px;
  line-height: 17px;
}

.total-integration{
  margin-top:40px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #81858E;
}
.content-info {
  margin-top: 41px;
}
.content-icon {
  width: 40px;
  height: 40px;
  background: rgba(237, 237, 240, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  width: 13px;
  height: 14.33px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 17px;
  color: #000916;
}

.btn-integration,
.btn-open-modal-dissociation-fanpage {
  height: 50px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  border: 0px;
}
.btn-open-modal-dissociation-fanpage:active {
  border: 0px;
}
.btn-integration {
  width: 216px;
  background-color: var(--background-color-white-light);
  margin-right: 25px;
}

.btn-open-modal-dissociation-fanpage {
  width: 209px;
  color: #ffffff;
  border: 0px;
  background-color: #FF0C37;
}

.btn-open-modal-dissociation-fanpage:hover {
  color: #ffffff;
  border: 0px;
}
</style>