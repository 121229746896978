<template>
  <div>
    <Modal :idModal="idModal">
      <div class="container">
        <div class="row p-4">
          <div class="col-12">
            <img
              class="float-right"
              :src="require('@/assets/icons/close.svg')"
              alt="Voltar"
              @click="$bvModal.hide(idModal)"
              id="icon-close"
            />
          </div>
          <div class="col-12" id="content-title">
            <p class="m-0" id="title-modal">Desassociar Fanpage</p>
            <small>Deseja continuar com a desassociação dessa Fanpage?</small>
          </div>
          <div class="col-12 p-0 content-btn-model-action">
            <button class="btn btn-cancel"
              @click="$bvModal.hide(idModal)"
            >Cancelar</button>
            <button class="btn btn-desassoation" @click="disassociate" :disabled="disabledBtn">Desassociar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from "../BaseModal.vue";
import { mapMutations } from 'vuex'

import LeadAdService from "@/services/resources/LeadAdService.js";
const LeadAdApiService = LeadAdService.build();

export default {
  name: "ModalDisassociateFanPage",
  props: {
    idModal: String,
  },
  components: {
    Modal,
  },
  data: () => ({
    isLoged: false,
    pages: [],
    page: null,
    disabledBtn: false,
    IdsFanpage: [],
  }),
  created: async function () {},
  methods: {
    ...mapMutations(['setMessage', 'loading']),
    async disassociate(){
      this.loading(true)
      this.disabledBtn = true
      
      const id = this.$route.params.id;
      let dataDeleted = await LeadAdApiService.destroy('/fanpage/'+id+'/disassociate')

      if(dataDeleted.error){
        this.setMessage({message: dataDeleted.error, bg:'danger'})
        this.disabledBtn = false
        this.loading(false)
        return;
      }
      this.disabledBtn = false
      this.loading(false)

      this.setMessage({message: 'Fanpage desassociada com sucesso!', bg:'success'})
      this.$router.push('/lead-ads')
    }
  },
};
</script>

<style scoped>
.content-btn-model-action {
  display: flex;
  justify-content: space-between;
}

.content-btn-model-action > button {
  width: 164.95px;
  height: 50px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.btn-cancel {
  background: #ededf0;
  color: #000000;
}
.btn-desassoation {
  background: #ff0c37;
  color: #ffffff;
}
.btn-desassoation:hover {
  color: #ffffff;
}
.modal-content.shadow {
  border-radius: 7px;
}

.modal-dialog.modal-md.modal-dialog-centered {
  width: 453px;
}

#icon-close {
  cursor: pointer;
}

#title-modal,
.title-step {
  color: #ff0c37;
  align-items: center;
  font-weight: 600;
  font-style: normal;
}

#title-modal {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px !important;
}

small {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #8c8a97;
}

#content-title {
  margin-bottom: 31.68px;
  margin-top: 15px;
}
</style>