<template>
  <div class="d-flex justify-content-center align-items-center mt-4">
    <h6 class="text-center mr-2 ">{{text}}</h6> 
    <img :src="require('@/assets/icons/negativo.svg')" alt="Integração" height="25" width="25">
  </div>
</template>

<script>
export default {
  props:{
    text: {
      type: String,
      required: true
    }
  }
}
</script>